import PropTypes from 'prop-types';
import queryString from 'query-string';
import {createEntity} from "../../libs/requestsManager";

import ListingCard from '@/components/ListingCard';
import Filters from '../components/Filters';
import ListingMap from '../components/Map';

import PersistedSearch from '../helpers/PersistedSearch';
import {ResultsMessage} from 'shared/displayHelpers';

const PER_PAGE = 30;
const DEFAULT_TERMS = {location: '', distance: 1000, organization_id: null};
const HIDDEN_DEFAULT_TERMS = {
  breed: null,
  gender: null,
  discipline: null,
  age_range: [0,20],
  height_range: [1,20],
  price_range: [0,5000]
};

const isOutOfBounds = (key, value) => {
  if (_.isNil(value)) return true;
  if (key === 'distance' && value == 1000) return true;
  if (key === 'location' && value === '') return true;
  if (key === 'organization_id' && value === '') return true;
  if (_.isArray(value) && _.isEqual(_.slice(value).sort(), _.get(HIDDEN_DEFAULT_TERMS, key))) return true;
  return false;
}

const setFilteredTerms = (terms) => {
  const filteredTerms = _.reduce(terms, (state, value, key) => {
    if (isOutOfBounds(key, value)) {
      return state;
    } else {
      return {...state, [key]: value};
    }
  }, {});
  const qs = queryString.stringify(filteredTerms);

  history.replaceState(history.state, '', `search${_.isEmpty(qs) ? '' : '?'}${qs}`);
}

const normalizedQueryString = (rawQueryString) => {
  return _.reduce(queryString.parse(rawQueryString), (state, value, key) => {
    if (key === 'distance') return {...state, [key]: parseInt(value, 10)};
    if (_.includes(['breed', 'discipline'], key)) return {...state, [key]: _.flatten([value])};
    if (_.isArray(value)) return {...state, [key]: [parseInt(value[0], 10), parseInt(value[1], 10)]};
    return {...state, [key]: value};
  }, {});
}

const useCachedTerms = () => {
  const terms = PersistedSearch.load() || DEFAULT_TERMS;
  setFilteredTerms(terms);
  return terms;
}

const useQueryFilterTerms = (queryFilters) => {
  return {...DEFAULT_TERMS, ...queryFilters};
}

export default class Search extends React.Component {
  static propTypes = {
    center: PropTypes.array,
    zoom: PropTypes.number,
  };

  constructor(props, context) {
    super(props, context);
    const queryFilters = normalizedQueryString(location.search);
    const terms = _.isEmpty(queryFilters) ? useCachedTerms() : useQueryFilterTerms(queryFilters);
    this.requestListings = _.debounce(this.postTerms.bind(this), 2000, {leading: true});
    this.showMore = _.throttle(this.moreListings, 250);
    this.state = {
      center: {},
      bounds: {},
      advanced: false,
      mode: 'DEFAULT',
      terms,
      results: [],
      maxResults: PER_PAGE,
      loading: true,
      locations: props.default_map_locations
    };
  }

  componentDidMount() {
    const selectedIndex = history.state ? history.state.index : 0;
    const roundedResults = Math.ceil(selectedIndex / PER_PAGE) * PER_PAGE;
    this.postTerms(roundedResults);

    document.addEventListener('scroll', this.watchScroll, false);
    const organization_id = useCachedTerms().organization_id;
    const organization = _.find(this.props.organizations, {id: _.toNumber(organization_id)})
    if(!organization && organization_id) this.clearFilters();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.watchScroll);
  }

  watchScroll = (e) => {
    const nearBottom = window.scrollY > document.body.offsetHeight - window.innerHeight*2;
    if (nearBottom) this.showMore();
  }

  moreListings = () => {
    const {count=PER_PAGE, results} = this.state;
    const maxResults = Math.min(count, _.get(this.state, 'maxResults', 0) + PER_PAGE);
    if (count > results.length) this.postTerms(maxResults, results.length);

    this.setState({maxResults});
  }

  setFilterTerm = (name, value) => {
    const terms = {..._.get(this.state, 'terms'), [name]: value};
    setFilteredTerms(terms);
    history.pushState({}, '');
    this.setState({terms, results: [], loading: true}, this.requestListings);
  }

  setTermLabel = ({target: {name, value}}) => {
    this.setState(({terms}) => ({terms: {...terms, [name]: value}}));
  }

  postTerms(maxIndex=PER_PAGE, offset=0) {
    maxIndex = _.max([PER_PAGE, maxIndex]);
    const limit = maxIndex - offset;
    if (!this.pendingRequest && limit > 0) {
      this.pendingRequest = true;
      this.setState({loading: true});

      createEntity('search', {terms: {...this.state.terms, limit, offset}})
        .then((response) => {
          const {terms, results, count, center, bounds, locations} = _.get(response, "data");
          PersistedSearch.save(terms);
          this.setState(({results: existingRecords}) => ({
            results: _.uniqBy([...existingRecords, ...results], 'id'),
            maxResults: results.length,
            center,
            bounds,
            locations,
            loading: false,
            count
          }));
          this.pendingRequest = false;
          _.delay(() => {this.scrollToElement && this.scrollToElement.scrollIntoView()}, 200);
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
          this.pendingRequest = false;
          this.setState({loading: false});
        });
    } else {
      this.setState({loading: false});
    }
  }

  setViewMode = (viewType) => {
    this.setState(({mode}) => ({
      mode: mode === viewType ? "DEFAULT" : viewType
    }));
  }

  clearFilters = () => {
    this.setState({terms: {...DEFAULT_TERMS, ...HIDDEN_DEFAULT_TERMS}, results: []}, () => {
      history.replaceState(history.state, '', 'search');
      PersistedSearch.clear();
      this.requestListings();
    });
  }

  persistIndex = (i) => {
    history.replaceState({index: i}, `Listing ${i}`, `#${i}`);
  }

  setScrollToRef = (element) => {
    this.scrollToElement = element;
  }

  render() {
    const {mode, loading, center, bounds, terms, results, maxResults, locations} = this.state;
    const {googleMapsKey, breeds, genders, disciplines, organizations, favorite_ids, adopter, showPinterest, userType, userID} = this.props;

    return (
      <div className="search">
        <div className="filters">
          <div className="container">
            <Filters
              ref={(filterComponent) => this.filters = filterComponent}
              advanced={mode === "FILTERS"}
              setFilterTerm={this.setFilterTerm}
              setTermLabel={this.setTermLabel}
              breeds={breeds}
              genders={genders}
              terms={terms}
              disciplines={disciplines}
              organizations={organizations}
              googleMapsKey={googleMapsKey}/>
            <div className="row">
              <div className="col-sm-4">
                <a href="#" className="btn btn-secondary mb-2 mb-sm-0 d-block d-sm-inline-block" onClick={() => this.setViewMode("FILTERS")}>
                  {mode === "FILTERS" ? 'Less Filters' : 'More Filters'}
                </a>
              </div>
              <div className="col-sm-4 text-sm-center">
                <a href="#" className="btn btn-outline-primary mb-2 mb-sm-0 d-block d-sm-inline-block" onClick={this.clearFilters}>
                  Clear Filters
                </a>
              </div>
              <div className="col-sm-4 text-sm-right">
                <a href="#" className="btn btn-primary d-block d-sm-inline-block" onClick={() => this.setViewMode("MAP")}>
                  {mode === "MAP" ? "Hide Map" : "View Map"}
                </a>
              </div>
            </div>
          </div>
        </div>

        {mode === "MAP" && <ListingMap {...{center, bounds, googleMapsKey, locations}}/>}

        <div className="container">
          <ResultsMessage count={results.length} loading={loading}/>

          {results.length > 0 && (
            <div className="card-row mt-3">
              {results.map((result, i) => (
                <div key={result.id}
                  ref={_.get(history, "state.index") === i ? this.setScrollToRef : undefined}
                  className="card-column"
                  onClick={() => this.persistIndex(i)}
                >
                  <ListingCard
                    listing={result}
                    adopter={adopter}
                    favorite={_.includes(favorite_ids, result.id)}
                    showPinterest={showPinterest}
                    userType={userType}
                    userID={userID}/>
                </div>
              ))}
            </div>
          )}
          {(_.inRange(results.length, 1, maxResults) && (results.length > 0)) && (
            <div className="text-center">
              <button onClick={this.showMore} className="btn btn-primary mb-4">
                Show More
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
