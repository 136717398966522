import moment from 'moment';
import pluralize from 'pluralize';
import PopoverShare from './PopoverShare';
import { preventDefault } from './shared/events';
import ProgressiveImage from './shared/ProgressiveImage';
import { addFavorite, deleteFavorite, breedsToShortString } from './shared/listing_helpers';

export const listingPath = (slug) => `/available-horses/${slug}`;

function daysRemainingLabel(days_remaining) {
  if (days_remaining <= 0 || days_remaining === undefined) {
    return "Listing expired";
  } else {
    return `Listing expires in ${_.round(days_remaining)} days`;
  }
}

export default class ListingCard extends React.Component {
  state = {
    days_remaining: this.props.listing.days_remaining,
    percent_remaining: this.props.listing.percent_remaining,
    favorite:  this.props.favorite
  }

  static getDerivedStateFromProps(props, state) {
    const {days_remaining, percent_remaining, status} = props.listing;
    if (status === "published") {
      return {days_remaining, percent_remaining};
    }
    return null;
  }

  toggleFavorite = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(({favorite}) => ({favorite: !favorite}), this.persistFavorite);
  }

  persistFavorite = () => {
    const {id} = this.props.listing;
    if (this.state.favorite) {
      addFavorite(id)
    } else {
      deleteFavorite(id);
    }
  }

  render() {
    const {listing, moved, contributor, adopter, noToggler, showPinterest, userType, userID} = this.props;
    const {
      id, slug, name, gender, breeds, age, height, location,
      status, status_reason, status_changed_at, cover_lite, cover
    } = listing;
    const breed = breedsToShortString(breeds);
    const published = status == 'published';
    const notAvailable = adopter && !published;
    const {days_remaining, percent_remaining, favorite} = this.state;

    return (
      <a href={!notAvailable && slug ? listingPath(slug) : "#"}
        className={classnames("card listing-card", {moved})}
        disabled={notAvailable}
        onClick={notAvailable ? preventDefault() : _.noop}
      >
        <div className="card-body">
          <ProgressiveImage src={cover} srcLite={cover_lite}/>
          {published && !noToggler && <PopoverShare userType={userType} userID={userID} listing={listing} showPinterest={showPinterest}/>}

          {adopter && (
            <i className={favorite ? "icon-heart" : "icon-heart-empty"}
              title={favorite ? "Remove from your stable" : "Add to your stable"}
              onClick={this.toggleFavorite} />
          )}
          <h5 className="card-title">{name}</h5>
          <div className="card-text">
            {location && (
              <p className="listing-data">
                {location.city}, {location.state}
              </p>
            )}
            <p className="listing-data">
              {_.compact([
                breed,
                gender,
                (age && pluralize('year', age, true)),
                (height && `${height} HH`)
              ]).join(' / ')}
            </p>

            {notAvailable && (
              <p className="listing-data"><em className="listing-expiration">This horse has been adopted!</em></p>
            )}

            {contributor && <>
              {published && (
                <div>
                  <div className="background-progress-bar"/>
                  <div className="progress-bar" style={{width: percent_remaining}}/>
                  <em className="listing-expiration">
                    {daysRemainingLabel(days_remaining)}
                  </em>
                </div>
              )}
              {status_reason && (
                <p className="listing-data">
                  {status == 'stalled' && 'In '}
                  {status_reason}: {moment(status_changed_at).format('M/D/YYYY')}
                </p>
              )}
            </>}

            {published && noToggler && <PopoverShare userType={userType} userID={userID} listing={listing} showPinterest={showPinterest} noToggler/>}
          </div>
        </div>
      </a>
    )
  }
}
