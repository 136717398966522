import superagent from 'superagent'
import axios from "axios";
import headers from '../ListingWizard/helpers/headers';

export const MAX_STEPS = 6;
export const MIN_STEPS = 1;

export const breedsToString = string => _.join(string, ', ');
export const breedsToShortString = string => string?.length > 1 ? `${string[0]}, Cross` : string;

export const submitListingAction = (listing, action, status_reason, callback) => {
  const {id} = listing;
  const initialStatus = _.pick(listing, ['id', 'status', 'status_reason', 'status_changed_at']);
  const newStatus = {id, status: action, status_reason, status_changed_at: new Date()};
  const msgData = action == "undo" ? initialStatus : newStatus;
  const csrfToken = _.get(document.querySelectorAll('meta[name="csrf-token"]'), '[0].content');

  superagent.patch(`/listings/${id}`)
    .set({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    }).responseType('json')
    .send({listing: msgData})
    .end(callback)
};

export function setInitialStatus(status, days_remaining, EXPIRING_SOON_DAYS) {
  if (status == "published" && days_remaining <= 0) {
    return "expired";
  } else if (status == "published" && days_remaining <= EXPIRING_SOON_DAYS) {
    return "expiring_soon";
  } else {
    return status;
  }
}

export function getAxios() {
  return axios.create({headers: headers()});
}

export function addFavorite(id) {
  return getAxios().post(`/api/v1/listings/${id}/favorite`, {})
    .then((response) => response.status)
    .catch((error) => console.warn(error));
}

export function deleteFavorite(id) {
  return getAxios().delete(`/api/v1/listings/${id}/favorite`)
    .then((response) => response.status)
    .catch((error) => console.warn(error));
}

export function updateListing(id, params) {
  return getAxios().patch(`/listings/${id}`, {listing: {id, ...params}});
}

export function uploadAttachments(key, id, files) {
  const formData = new FormData();
  files.forEach((file) => formData.append(`listing[${key}][]`, file));
  formData.append('listing[id]', id);
  return getAxios().post(`/upload.json`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
}

export function deleteListingMedium(id) {
  return getAxios().delete(`/listing_media/${id}`)
}

export function deleteListingDocument(id) {
  return getAxios().delete(`/listing_documents/${id}`)
}

export function updateImageOrder(id, order) {
  return getAxios().post(`/media_order/${id}`, { media_order: order });
}

export function uploadImageCropping(id, {width, height, x, y}) {
  return getAxios().patch(`/listing_media/${id}`, {
    listing_medium: {crop_width: width, crop_height: height, crop_x: x, crop_y: y}
  });
}

export function importCSV(file, mapping) {
  const formData = new FormData();
  formData.append('csv_upload[carrierwave_file]', file);
  if (mapping) formData.append('csv_upload[mapping]', JSON.stringify(mapping));
  return getAxios().post(`/csv_uploads.json`, formData, {headers: {'Content-Type': 'multipart/form-data'}});
}

export function exportCSV(mapping, filters) {
  return getAxios().post(`/csv_exports`, {csv_export: {mapping, filters}});
}

export function createHelpRequest(csv_upload_id) {
  return getAxios().post(`/csv_uploads/${csv_upload_id}/help_requests`)
}
