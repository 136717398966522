import DashboardListingCard from './DashboardListingCard';
import ContributorSearch from './ContributorSearch';
import {SectionTitle, LinkCard, ResultsMessage} from './shared/displayHelpers';

import {createEntity} from "./libs/requestsManager";

export default class Dashboard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.requestListings = _.debounce(this.postTerms.bind(this), 2000, {leading: true});
    this.state = {
      search: "",
      results: [],
      loading: true,
      listings: this.props.listings || []
    };
  }

  postTerms() {
    if (!this.pendingRequest) {
      this.pendingRequest = true;
      this.setState({loading: true});
      createEntity('contributor_search', {search: this.state.search, view_scope: _.lowerCase(this.props.scope)})
        .then((response) => {
          const {results} = _.get(response, "data");

          this.setState({results: _.uniqBy([...results], 'id') });
          this.setState({loading: false});
          this.pendingRequest = false;
        })
        .catch((error) => {
          if (error) {console.log(error);}
          this.pendingRequest = false;
          this.setState({loading: false});
        });
    } else {
      this.setState({loading: false});
    }
  }

  setFilterTerm = (string) => this.setState({search: string, loading: true}, this.requestListings);

  render() {
    const {scope, EXPIRING_SOON_DAYS, STATUS_REASONS, MAX_DASHBOARD_COUNT, LISTING_SCOPES, showPinterest, userType, userID} = this.props;
    const {search, results, loading, listings } = this.state;
    const dashboardHasNoResults = scope == "all" && _.isEmpty(search);

    const recentListings = this.props.listings.filter((l) => l.updated_at >= this.props.EXPIRING_SOON_DAYS)
    const expiringListings = this.props.listings.filter((l) => l.updated_at < this.props.EXPIRING_SOON_DAYS)

    const mostRecent = (listings) => listings.reverse().slice(0, MAX_DASHBOARD_COUNT - 1);

    const searchPlaceholder = (() => {
      if (LISTING_SCOPES.includes(_.lowerCase(scope))) {
       return `Search your organization's ${scope} listings`
      } else {
       return "Search all of your organization's listings"
      }
    })();

    return (
      <div className="listings dashboard my-3">
        <div className="d-flex flex-row ">
          <ContributorSearch setFilterTerm={this.setFilterTerm} search={search} searchPlaceholder={searchPlaceholder}/>
          {scope !== "all" && (
            <a href={`/csv_exports/new?scope=${_.snakeCase(scope)}`} className="btn btn-secondary flex-shrink-0 ml-2 d-none d-sm-inline-block">
              <i className="icon-csv-documenticon"/> Download CSV
            </a>
          )}
        </div>
        <div className={classnames({"pt-3": !dashboardHasNoResults})}>
          {!_.isEmpty(search) && <>
            <ResultsMessage count={results.length} loading={loading} />
            {!_.isEmpty(results) && (
              <div className="search-results card-row">
                {_.map(results, (listing) => (
                  <DashboardListingCard key={listing.id} {...{listing, EXPIRING_SOON_DAYS, STATUS_REASONS, MAX_DASHBOARD_COUNT, showPinterest, userType, userID}}/>
                ))}
              </div>
            )}
          </>}

          {scope == "all" ? <>
            {!_.isEmpty(expiringListings) && <>
              <SectionTitle text="Expiring Soon"/>

              <div className="card-row">
                {_.map(mostRecent(expiringListings), (listing) =>
                  <DashboardListingCard key={listing.id} {...{listing, EXPIRING_SOON_DAYS, STATUS_REASONS, MAX_DASHBOARD_COUNT, showPinterest, userType, userID}}/>
                )}
                {!(expiringListings.length < MAX_DASHBOARD_COUNT) && <LinkCard linkTo="expiringSoon" text="View All Expiring Listings"/>}
              </div>
            </>}

            <SectionTitle text="Active Listings"/>
            <div className="card-row">
              {_.map(mostRecent(recentListings), (listing) =>
                <DashboardListingCard key={listing.id} {...{listing, EXPIRING_SOON_DAYS, STATUS_REASONS, MAX_DASHBOARD_COUNT, showPinterest, userType, userID}}/>
              )}
              {_.isEmpty(recentListings) && <LinkCard linkTo="new" text="Create Listing"/>}
              {!(recentListings.length < MAX_DASHBOARD_COUNT)  &&
                <LinkCard linkTo="active" text="View All Active Listings"/>
              }
            </div>
          </> : (
            <div>
              {_.isEmpty(search) && (
                _.isEmpty(listings) ? (
                  <p className="empty-state">There are no {scope} listings</p>
                ) : (
                  <div className="search-results card-row">
                    {scope == "Drafted" && <LinkCard linkTo="new" text="Create Listing"/>}
                    {_.map(listings, (listing) => (
                      <DashboardListingCard  key={listing.id} {...{
                        listing, EXPIRING_SOON_DAYS, STATUS_REASONS, MAX_DASHBOARD_COUNT, showPinterest, userType, userID
                      }}/>
                    ))}
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
