import { Provider as StepProvider } from '@/contexts/StepContext';
import { Provider as ListingProvider } from '@/contexts/ListingContext';

import FormBox from './containers/FormBox';
import InfoBox from './containers/InfoBox';

export default function ListingWizardApp({showPinterest, userType, userID, ...listing}) {
  return (
    <ListingProvider value={listing}>
      <StepProvider value={listing.step}>
          <div className="row listing-wizard">
            <FormBox showPinterest={showPinterest} userType={userType} userID={userID}/>
            <InfoBox/>
          </div>
        </StepProvider>
    </ListingProvider>
  );
}

