import { useContext, useEffect } from 'react';
import { preventDefault } from 'shared/events';

import flash from 'shared/flash';
import { MAX_STEPS } from 'shared/listing_helpers';

import StepContext from '@/contexts/StepContext';
import ListingContext from '@/contexts/ListingContext';

import StepOne from '../ListingWizard/components/steps/StepOne';
import StepTwo from '../ListingWizard/components/steps/StepTwo';
import StepThree from '../ListingWizard/components/steps/StepThree';
import StepFour from '../ListingWizard/components/steps/StepFour';
import StepFive from '../ListingWizard/components/steps/StepFive';
import PreviewStep from '../ListingWizard/components/steps/PreviewStep';

const StepComponents = [StepOne, StepTwo, StepThree, StepFour, StepFive, PreviewStep];

const allowUpdateToBeCalledFirst = (func) => () => _.defer(func);

const published = ({status}={}) => status === 'published';
const indexPath = (listing) => {
  switch (listing.status) {
  case 'published':
    return listing.days_remaining >= 0 ? '/listings/active' : '/listings/expired';
  case 'stalled':
    return '/listings/stalled';
  default:
    return '/listings/drafted';
  }
};

export default function FormBox({showPinterest, userType, userID}) {
  const {listing, errors, getErrors, publish, updateField, validateStep} = useContext(ListingContext);
  const {step, nextStep, prevStep} = useContext(StepContext);

  useEffect(() => {
    if (listing.name) validateStep(step);
  }, []);

  const publishAndCreate = async (_e) => {
    try {
      await publish();
      window.location.href = '/listings/new';
    } catch(e) {
      console.warn(e);
    }
  };

  const publishAndShow = async (_e) => {
    try {
      const {slug} = await publish();
      if (slug) {
        window.location.href = `/available-horses/${slug}`;
      } else {
        window.location.href = '/dashboard';
      }
    } catch(e) {
      console.warn(e);
      if (!_.isEmpty(getErrors())) {
        flash("errors", "Could not save changes, some fields were invalid.");
      }
    }
  };

  const saveChanges = async (_e) => {
    await validateStep(step);
    if (_.isEmpty(getErrors())) {
      window.location.href = indexPath(listing) + '?success=Changes+Saved';
    } else {
      flash("errors", "Could not save changes, some fields were invalid.");
    }
  };

  const StepComponent = (step < StepComponents.length) ? StepComponents[step-1] : _.last(StepComponents);
  const nextButton = step === MAX_STEPS ? (
    <button
      type="button"
      className="btn btn-primary px-5"
      onClick={preventDefault(allowUpdateToBeCalledFirst(publishAndShow))}
    >
      Publish
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-primary px-4"
      onClick={preventDefault(allowUpdateToBeCalledFirst(() => nextStep(false )))}
    >
      Continue
    </button>
  )

  const backButton = step === MAX_STEPS ? (
    <button
      type="button"
      className="btn btn-outline-primary px-4"
      onClick={preventDefault(allowUpdateToBeCalledFirst(publishAndCreate))}
    >
      Create Another Listing
    </button>
  ) : (
    <span
      className="fake-link"
      onClick={preventDefault(allowUpdateToBeCalledFirst(prevStep))}
    >
      ← Back
    </span>
  )

  const saveButton = (
    <button
      type="button"
      className="btn btn-outline-primary px-4"
      onClick={preventDefault(allowUpdateToBeCalledFirst(saveChanges))}
    >
      {published(listing) ? 'Save Changes' : 'Save Draft'}
    </button>
  );

  return (
    <form className="form-box">
      <StepComponent {...listing} updateField={updateField} errors={errors} showPinterest={showPinterest} userType={userType} userID={userID}/>
      <hr/>
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center" style={{gap: '10px'}}>
        {step > 1 && backButton}
        {saveButton}
        {nextButton}
      </div>
    </form>
  );
}
