import {Typeahead, AsyncTypeahead} from 'react-bootstrap-typeahead';
import { Multiselect } from 'multiselect-react-dropdown';

import Slider from '../../Fields/Slider';
import Select, {listToSelectOptions} from '../../Fields/Select';
import LocationTypeahead from './LocationTypeahead';

const ZIP_CODE_MATCHER = /^\d{5}(-\d{4})?$/;
const THREEPLUS_LETTERS_MATCHER = /[a-z]{3,}/i;
const BLUE = "var(--secondary)";

const isInvalidLocation = (location) => !(
  _.isString(location) && (
    ZIP_CODE_MATCHER.test(location) || THREEPLUS_LETTERS_MATCHER.test(location)
  )
);
const organizationLabel = ({organizations, terms:{organization_id}}) => (
  _.get(_.find(organizations, {id: _.toNumber(organization_id)}), 'name') || ""
)

export default class Filters extends React.Component {
  state = {
    location: this.props.terms.location || '',
    organization: organizationLabel(this.props)
  };

  componentDidUpdate(prevProps) {
    if (prevProps.terms.location != this.props.terms.location) {
      this.setState({location: this.props.terms.location})
    }
    if (prevProps.terms.organization_id != this.props.terms.organization_id) {
      this.setState({organization: organizationLabel(this.props)})
    }
  }

  emptyTypeahead = (string, label) => {
    this.setState({[label.replace("_id", "")]: string})
    if (_.isEmpty(string)) {
      this.props.setFilterTerm(label, string);
    }
  }

  selectTypeahead = (selected, name, property) => {
    if (!_.isEmpty(selected)) {
      this.setState({[name.replace("_id", "")]: selected})
      this.props.setFilterTerm(name, _.get(selected, property, selected));
    }
  }

  render() {
    const {
      advanced,
      setFilterTerm,
      terms,
      setTermLabel,
      organizations,
      breeds,
      genders,
      disciplines,
      googleMapsKey
    } = this.props;
    const {isLoadingLocation, locationOptions, location, organization} = this.state;
    const organizationOptions = organizations.map((org) => ({id: org.id, label: org.name}));
    const onChange = ({target: {name, value}}) => setFilterTerm(name, value);

    return (
      <div>
        <div className="row">
          <div className="filter search col-sm-12">
            <label htmlFor="search">Search</label>
            <input className="search-field" id="search" name="search" value={terms["search"] || ""} onChange={onChange} placeholder="Search by horse name or other keywords" />
          </div>
        </div>
        {advanced && <div className="row">
          <div className="filter organization col-sm-4">
            <label htmlFor="organization_id">Organization</label>
            <Typeahead
              id="organization_id"
              name="organization_id"
              placeholder="Select or search for an organization"
              selected={[organization]}
              options={organizationOptions}
              onChange={([selected]) => this.selectTypeahead(selected, "organization_id", "id")}
              onInputChange={(string) => this.emptyTypeahead(string, "organization_id")}
              inputProps={{autoComplete: "chrome-off"}} />
          </div>
          <div className="filter location col-sm-2">
            <label htmlFor="location">Location</label>
            <LocationTypeahead
              selected={location}
              onChange={(selected) => this.selectTypeahead(selected, "location")}
              onInputChange={(string) => this.emptyTypeahead(string, "location")}
              googleMapsKey={googleMapsKey}/>
          </div>
          <div className="filter distance col-sm-6">
            <label htmlFor="distance">Distance</label>
            <Slider
              id="distance"
              name="distance"
              before="within "
              after=" miles - there are many options to bring your Right Horse to you"
              min={25}
              max={1000}
              step={25}
              value={terms['distance']}
              disabled={isInvalidLocation(terms.location)}
              setTerm={onChange}
              setTermLabel={setTermLabel}
              className={classnames("slider", {disabled: isInvalidLocation(terms.location)})} />
          </div>
        </div>}
        {advanced && <div className="row">
          <div className="filter breed col-sm-4">
            <label>Breeds</label>
            <Multiselect
              style={{
                chips: {background: BLUE},
                searchBox: {background: "white"},
                option: {color: "var(--gray-900)", background: "white"},
                optionListContainer: {"z-index": 3}
              }}
              options={breeds}
              isObject={false}
              closeIcon="cancel"
              placeholder="Select one or multiple"
              selectedValues={terms['breed']}
              onSelect={_.partial(setFilterTerm, 'breed')}
              onRemove={_.partial(setFilterTerm, 'breed')}
            />
          </div>
          <div className="filter gender col-sm-4">
            <Select
              label="Gender"
              fieldName="gender"
              defaultOption={[null, 'All genders']}
              options={listToSelectOptions(genders)}
              value={terms['gender']}
              updateField={onChange} />
          </div>
          <div className="filter discipline col-sm-4">
            <label>Potential For</label>
              <Multiselect
                style={{
                  chips: {background: BLUE},
                  searchBox: {background: "white"},
                  option: {color: "var(--gray-900)", background: "white"},
                  optionListContainer: {"z-index": 3}
                }}
                options={disciplines}
                isObject={false}
                closeIcon="cancel"
                placeholder="Select one or multiple"
                selectedValues={terms['discipline']}
                onSelect={_.partial(setFilterTerm, 'discipline')}
                onRemove={_.partial(setFilterTerm, 'discipline')}
              />
          </div>
          <div className="filter age_range col-sm-4">
            <label htmlFor="age_range">Age range</label>
            <Slider
              name="age_range"
              min={0}
              max={20}
              value={terms['age_range'] || [0, 20]}
              setTerm={onChange}
              setTermLabel={setTermLabel} />
          </div>
          <div className="filter height_range col-sm-4">
            <label>Height range</label>
            <Slider
              name="height_range"
              before=""
              after=" HH"
              min={1}
              max={20}
              value={terms['height_range'] || [1, 20]}
              setTerm={onChange}
              setTermLabel={setTermLabel} />
          </div>
          <div className="filter price_range col-sm-4">
            <label htmlFor="price_range">Adoption fee range</label>
            <Slider
              name="price_range"
              before="$"
              min={0}
              max={5000}
              step={100}
              value={terms['price_range'] || [0, 5000]}
              setTerm={onChange}
              setTermLabel={setTermLabel} />
          </div>
        </div>}
      </div>
    );
  }
}
