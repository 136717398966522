import { Popover, PopoverBody } from 'reactstrap';
import copy from 'copy-text-to-clipboard';
import ahoy from 'ahoy.js';
import loadPinterest from './shared/pinterest';
import { breedsToString } from './shared/listing_helpers';

const X_HASHTAGS = [
  "myrighthorse",
  "aspca"
]

const FACEBOOK_HASHTAG = "myrighthorse"

export default class PopoverShare extends React.Component {
  state = {popoverOpen: false}

  trackShare = (share_type) => {
    const {listing: {id:listing_id}, userType, userID} = this.props;
    const ahoy_props = {share_type, listing_id, url: window.location.href};

    if (userType && userID) {
      ahoy_props.user_type = userType
      ahoy_props.user_id = userID
    }

    ahoy.track("LISTING_SHARE", ahoy_props);
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  listingUrl = () => `${window.location.origin}/available-horses/${this.props.listing.slug}`

  copyLink = () => {
    if (copy(this.listingUrl())) {
      $('body').append('<div class="notice success"><div class="container">Link Copied!</div></div>');
    }
  }

  fbShare = () => {
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.listingUrl()}&hashtag=%23${FACEBOOK_HASHTAG}`;
    window.open(fbUrl, 'facebook-popup', 'height=350, width=600');
  }

  xShare = () => {
    const {breeds}  = this.props.listing;
    const hashtags = _.join([X_HASHTAGS, breedsToString(breeds).replace(/ /g, '')], ',');
    const xUrl = `https://twitter.com/share?url=${this.listingUrl()}&hashtags=${hashtags}`;
    window.open(xUrl, 'twitter-popup', 'height=350,width=600');
  }

  pinterestShare = async () => {
    const {cover, name, age, breeds, gender, location}  = this.props.listing;
    const PinUtils = await loadPinterest();

    if (!PinUtils) return alert("Pintrest did not successfully load.");

    PinUtils.pinOne({
      url: this.listingUrl(),
      media: cover,
      description: `${name} is a ${age} year old #${breedsToString(breeds)} ${gender} in ${location.city}, ${location.state}. #myrighthorse`
    });
  }

  share = (source, method) => (e) => {
    e.preventDefault();
    this.trackShare(source);
    method();
  }

  render() {
    const {listing: {id}, noToggler, showPinterest} = this.props;

    const SocialShareButtons = <>
      <i className="icon-copy-regular share-link"
        title="Copy link to Clipboard"
        onClick={this.share('link', this.copyLink)}/>
      <i className="icon-facebook-squared share-link"
        title="Share to Facebook"
        onClick={this.share('facebook', this.fbShare)}/>
      <i className="icon-x-twitter share-link"
        title="Share to X"
        onClick={this.share('twitter', this.xShare)}/>
      {(showPinterest === "true") &&
        <i className="icon-pinterest-squared share-link"
        title="Share to Pinterest"
        onClick={this.share('pintrest', this.pinterestShare)}/>
      }
    </>;

    return noToggler ? (
      <span className="col-12 justify-content-around d-flex">
        {SocialShareButtons}
      </span>
    ) : <>
      <span>
        <i id={`Popover-${id}`} className="icon-share-solid share-popover" onClick={this.toggle}/>
        <Popover
          placement="left"
          isOpen={this.state.popoverOpen}
          target={`Popover-${id}`}
          toggle={this.toggle}>
          <PopoverBody>{SocialShareButtons}</PopoverBody>
        </Popover>
      </span>
    </>;
  }
}
